<template>
  <div></div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      userData: "authnew/getUserData"
    })
  },
  mounted() {
    setTimeout(() => {
      this.logOutUser();
      // setTimeout(() => {
      //   // this.$router.push("/job-seeker/login");
      //   location.href = "/job-seeker/login";
      // }, 111);
    }, 111);
  },
  methods: {
    ...mapMutations({
      logOutUser: "authnew/logOutUser"
    })
  }
};
</script>
